<template>
    <div class="circle-w">
        <div>
            <img class="head-banner-w"  :src="BannerSrc" alt="">
        </div>

        <vue-flex-waterfall
                class="mdui-container mdui-m-b-4 mdui-p-a-0"
                col="5"
                col-spacing="15"
                :break-at="breakAt"
                :break-by-container="true"
                @order-update="onOrderUpdate"
                style="align-content: center;"
        >
            <circle-item  class="circle-item" v-for="(item,index) in circleList" @click.native="goCircleDetails(item.id,item.picUrl)" :index="index" :key="index"
                          :itemData="item" />
        </vue-flex-waterfall>
    </div>
</template>

<script>
    import VueFlexWaterfall from 'vue-flex-waterfall';
    import circleItem from './components/circleItem';

    export default {
        name: 'water-fall',
        components: {
            VueFlexWaterfall,
            circleItem
        },
        data: () => ({
            items: [],
            nextI: 15,
            breakAt: { 900: 3, 600: 2, 300: 2 },
            page: {
                pageNum: 1, // 当前页数
                pageSize: 20, // 每页显示多少条
            },
            circleList: [],
            array: [] ,//定义空数组存储元素高度
            BannerSrc: ''
        }),
        mounted() {
            this.getCommunityList()
            this.getBanner()
        },
        methods: {
            deleteItem(i) {
                this.items.splice(i, 1);
            },
            onOrderUpdate() {
                console.log('order updated');
            },
            getCommunityList() {
                this.$api.home.communityList(this.page, res => {
                    if (res.code === 200) {
                        this.circleList = this.circleList.concat(res.rows)
                        this.circleList = this.circleList.concat(res.rows)
                    }
                });
            },
            //获取banner
            getBanner(){
                this.$api.home.bannerList({type:1},res=>{
                    if (res.code == 200) {
                        this.BannerSrc = res.rows[0].picUrl
                    }
                })
            },
            goCircleDetails(id,picUrl){
                console.log(id)
                this.$router.push('/circleDetails?id='+id+'&picUrl='+picUrl)
            },
        },
        created() {
            console.log('break-at', JSON.parse(JSON.stringify(this.breakAt)));
        },
    };
</script>

<style>
    .circle-w {
        padding: 28px 30px 30px;
        width: 100%;
        box-sizing: border-box;
        background-color: #F6F7F9;
        min-height: 100vh;
    }

    .head-banner-w {
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-radius: 15px;
        opacity: 1;
    }

    .circle-item {
        margin-top: 12px;
        /*margin: 6px;*/
    }
</style>