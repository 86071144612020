<template>
  <div class="circleItem">
    <van-image v-RandomBgColor class="waterfall-img" :src="itemData.picUrl" alt="" fit="cover" />
    <div class="content">
      <h3 class="name van-ellipsis">{{itemData.name}}</h3>
      <p class="dec">{{itemData.description}}</p>
    </div>
  </div>
</template>

<script>
  import {Image} from "vant";
  import Vue from "vue";
  Vue.use(Image);

  export default {
    name: "circleItem",
    props:{
       itemData:Object,
       index:Number
    },
    data() {
      return {};
    },
    created() {},
    methods: {}
  };
</script>

<style lang="less" scoped>
  .circleItem {
    width: 335px;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;

    .waterfall-img{
      border-radius:  20px 20px 0 0;
      overflow: hidden;
      display: block;
      width: 100%;
      height: auto;
    }

    //img {
    //  display: block;
    //  width: 100%;
    //  height: auto;
    //}

    .content {
      padding: 16px 12px 16px 20px;
      box-sizing: border-box;
      .name {
        font-size: 28px;
        color: #4B4B4B;
        line-height: 40px;
        margin-bottom: 8px;
      }

      .dec {
        font-size: 22px;
        font-weight: 500;
        color: #999999;
        line-height: 32px;
      }
    }

  }
</style>
